export default {
  page: {
    title: {
      base: 'Πύλη πληρωμής Autopay',
      start: 'Επιλογή τρόπου πληρωμής',
      visadata: 'Πληρωμή VisaMobile',
      continue: 'Συνέχεια - Επιλογή τρόπου πληρωμής',
      wait: 'Αναμονή',
      confirmation: 'Επιβεβαίωση πληρωμής',
      thankYou: 'Ευχαριστώ για την πληρωμή',
      error: 'Σφάλμα σελίδας',
      errorNotFound: 'Η σελίδα δεν βρέθηκε',
      secureBack: 'Φόρτωση σελίδας',
      maintenance: 'Σελίδα υπό συντήρηση',
      regulationsApproval: 'Κανονισμοί και Πολιτική Απορρήτου',
      toTransferData: 'Στοιχεία τραπεζικής μεταφοράς',
      sessionTimeout: 'Σελίδα μετά το τέλος της σύνδεσης',
      paywayError: 'Το κανάλι πληρωμής δεν είναι διαθέσιμο'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Αυξήστε το μέγεθος της γραμματοσειράς',
      decreaseFontSize: 'Μειώστε το μέγεθος της γραμματοσειράς',
      changeContrast: 'Αλλάξτε την αντίθεση',
      changeLanguage: 'Αλλάξτε τη γλώσσα της σελίδας',
      skipToContent: 'Μετάβαση στο περιεχόμενο',
      skipToContentLabel: 'Μετάβαση στο κύριο περιεχόμενο',
      logoTitle: 'Λογότυπο ιστοσελίδας'
    },
    sessionTimer: {
      timeOut: 'Η σύνδεση έχει λήξει',
      sessionEnd: 'Λήξη σύνδεσης σε:',
      label: 'Επεκτείνετε τον χρόνο της συνεδρίας σας',
      button: 'Επεκτείνω'
    }
  },
  footer: {
    text1: 'Έχετε επιπλέον ερωτήσεις σχετικά με τις online πληρωμές ή άλλες υπηρεσίες μας; <a title="Ανοίγει σε νέο παράθυρο" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Πηγαίνετε στη σελίδα Βοήθειας</a>',
    text2: {
      part1: 'Ο διαχειριστής των προσωπικών δεδομένων είναι η Autopay S.A.',
      part2: 'οδ. Powstańców Warszawy 6, 81-718 Sopot. Η παροχή προσωπικών δεδομένων είναι προαιρετική, αλλά απαραίτητη για την εκτέλεση της αποστολής που υποβάλατε. Η νομική βάση, ο σκοπός, η διάρκεια επεξεργασίας των προσωπικών δεδομένων και τα δικαιώματα που έχουν οι χρήστες, καθώς και άλλες σημαντικές πληροφορίες σχετικά με τις αρχές επεξεργασίας προσωπικών δεδομένων καθορίζονται λεπτομερώς στην <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Πηγαίνετε στη σελίδα με το έγγραφο Πολιτική Απορρήτου (128 KB, PDF)" rel="noreferrer">πολιτική απορρήτου της εταιρείας Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Προβολή επιπλέον πληροφοριών',
    linkShowMoreText: 'Διαβάστε περισσότερα',
    linkShowLessTitle: 'Απόκρυψη επιπλέον πληροφοριών',
    linkShowLessText: 'Διαβάστε λιγότερα'
  },
  paywall: {
    header: 'Επιλέξτε μέθοδο πληρωμής',
    info: {
      paywayNotAvailable: 'Λυπούμαστε, αλλά φαίνεται ότι η επιλεγμένη μέθοδος πληρωμής δεν είναι διαθέσιμη αυτή τη στιγμή στη συσκευή σας.',
      applePayNotAvailable: 'Λυπούμαστε, αλλά φαίνεται ότι η πληρωμή με Apple Pay δεν είναι διαθέσιμη αυτή τη στιγμή στη συσκευή σας.',
      googlePayNotAvailable: 'Λυπούμαστε, αλλά φαίνεται ότι η πληρωμή με Google Pay δεν είναι διαθέσιμη αυτή τη στιγμή στη συσκευή σας.'
    },
    paywayGroup: {
      inactiveMessage: 'Η πληρωμή δεν είναι διαθέσιμη αυτή τη στιγμή',
      notice: {
        novelty: 'ΝΕΟ',
        promotion: 'ΠΡΟΩΘΗΣΗ',
        recommended: 'ΣΥΝΙΣΤΑΤΑΙ',
        mastercard: 'Η ΠΙΟ ΑΣΦΑΛΗ ΠΛΗΡΩΜΗ ΣΤΟ ΔΙΑΔΙΚΤΥΟ',
        mastercardC2P: 'Η ΠΙΟ ΑΣΦΑΛΗ ΠΛΗΡΩΜΗ ΣΤΟ ΔΙΑΔΙΚΤΥΟ',
        blik: 'BLIKOMANIA – εγγραφείτε και κερδίστε διαμάντια!',
        visaMobile: 'Πληρώστε εύκολα και με ασφάλεια'
      },
      bank: {
        many: 'τραπεζών ',
        one: 'τράπεζα ',
        two: 'τράπεζες '
      },
      wallet: {
        many: 'πορτοφολιών ',
        one: 'πορτοφόλι ',
        two: 'πορτοφόλια '
      },
      otp: {
        many: 'τρόπων ',
        one: 'τρόπος ',
        two: 'τρόποι '
      },
      blikPayLater: {
        info: 'Μάθετε περισσότερα '
      }
    },
    paywayList: {
      label: 'Ομάδα τύπων πληρωμής',
      linkSelectOther: 'Επιλέξτε άλλο '
    },
    paywaySingle: {
      accountAtAnotherBank: 'Έχω λογαριασμό σε άλλη τράπεζα '
    },
    paywaySwitch: {
      methodShortSingle: 'Μία φορά ',
      methodLongSingle: 'Μοναδικές πληρωμές ',
      methodShortAuto: 'Επαναλαμβανόμενη ',
      methodLongAuto: 'Επαναλαμβανόμενες πληρωμές '
    },
    form: {
      payway: {
        label: 'Επιλέξτε πληρωμή '
      },
      card: {
        label: 'Εισαγάγετε τα στοιχεία της κάρτας ',
        iframe: {
          title: 'Φόρμα για συμπλήρωση στοιχείων πιστωτικής κάρτας '
        }
      },
      visaMobile: {
        iframe: {
          title: 'Φόρμα για συμπλήρωση αριθμού τηλεφώνου '
        },
        status: {
          pending: {
            statusText: 'Αναμονή επιβεβαίωσης ',
            header: 'Επιβεβαιώστε την πληρωμή στην εφαρμογή',
            descriptionText1: 'Επιβεβαιώστε την πληρωμή στην εφαρμογή Visa Mobile ή στην τραπεζική εφαρμογή με ενεργοποιημένη την υπηρεσία Visa Mobile ',
            descriptionText2: 'Μετά την επιβεβαίωση της συναλλαγής στην εφαρμογή, περιμένετε για το αποτέλεσμα της πληρωμής ',
            descriptionText3: 'Η ακύρωση της συναλλαγής είναι σε εξέλιξη ',
            cancelButton: 'Ακύρωση πληρωμής '
          },
          success: {
            statusText: 'Σας ευχαριστούμε για την αγορά',
            descriptionText: 'Η πληρωμή ολοκληρώθηκε επιτυχώς '
          },
          error: {
            statusText: 'Βεβαιωθείτε ότι έχετε επαρκή κεφάλαια στην κάρτα πληρωμής που χρησιμοποιήθηκε για την πληρωμή Visa Mobile.',
            descriptionText: 'Η πληρωμή απορρίφθηκε '
          }
        },
        apps: 'Εφαρμογές που υποστηρίζουν πληρωμές Visa Mobile ',
        help: 'Χρειάζεστε βοήθεια ?',
        info: 'Μάθετε περισσότερα ',
        secure: 'Ασφαλής πληρωμή χάρη στην κρυπτογράφηση SSL ',
        modal2: {
          header: 'Για να επιβεβαιώσετε την πληρωμή, ανοίξτε την εφαρμογή Visa Mobile ή την τραπεζική εφαρμογή με ενεργοποιημένη την υπηρεσία Visa Mobile ',
          listText1: 'έχετε εγκατεστημένη την εφαρμογή Visa Mobile ή ενεργοποιημένη την υπηρεσία Visa Mobile στην τραπεζική σας εφαρμογή ',
          listText2: 'έχετε ενεργοποιήσει τις ειδοποιήσεις στην εφαρμογή Visa Mobile ή στην τραπεζική εφαρμογή με ενεργοποιημένη την υπηρεσία Visa Mobile',
          listText3: 'ο αριθμός που δώσατε είναι σωστός: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Βεβαιωθείτε επίσης ότι... '
        }
      },
      click2pay: {
        loaderText: 'We are looking for your e-mail address<br>in <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Save your card',
          text2: 'Your data will be saved safely in <strong>Mastercard Click to Pay</strong>',
          link: 'Learn more'
        },
        email: {
          labelForClick2Pay: 'Email address for payment notifications'
        },
        cardSelect: {
          selected: 'Selected',
          select: 'Select',
          payOther: 'Enter your card details',
          back: 'Back'
        },
        cardSelected: {
          header: 'Use a card saved in Click to Pay',
          date: 'valid until',
          buttonChange: 'Change'
        },
        codeForm: {
          description: 'We found your email address in Click to Pay.',
          codeSentEmail: 'Enter the one-time code we sent to {email} to log in to your account.',
          codeSentPhone: 'Enter the one-time SMS code that we sent to {phone} to log in to your account.',
          codeLabel: 'Enter code',
          codePlaceholder: 'enter code',
          codeLabelFlow: 'Code',
          codeSendLabel: 'Send new code',
          codeSendAriaLabel: 'via',
          codeSendAriaLabel2: 'or',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'email',
          trustedLabel: 'Add this device to your trusted list<br><span>Next time we will recognize this device and you will pay without logging in</span>',
          buttonSubmit: 'Next',
          buttonChangeCard: 'Enter your card details'
        },
        info: {
          description: 'Card payment is encrypted and secure. The transaction will be authorized using 3DSecure on the bank\'s website.'
        },
        loginForm: {
          emailLabel: 'Enter your email address to log in to Click to Pay',
          emailPlaceholder: 'email address',
          emailLabelFlow: 'Email',
          buttonSubmit: 'Log in',
          buttonBack: 'Back'
        },
        newUserForm: {
          header: 'Save card in Click to Pay',
          description: 'We need a few more details to save your card.',
          emailLabel: 'Enter email address',
          emailPlaceholder: 'email address',
          emailLabelFlow: 'Email',
          countryLabel: 'Country',
          firstnameLabel: 'Name',
          firstnamePlaceholder: 'name',
          firstnameLabelFlow: 'Name',
          lastnameLabel: 'Last name',
          lastnamePlaceholder: 'last name',
          lastnameLabelFlow: 'Last name',
          phoneLabel: 'Phone number',
          phonePlaceholder: 'phone number',
          phoneLabelFlow: 'Phone',
          countryCodePlaceholder: 'code',
          countryCodeLabelFlow: 'Code',
          trustedLabel: 'Add this device to your trusted list<br><span>Next time we will recognize this device and you will pay without logging in</span>',
          termsLabel: 'By continuing, you accept <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Terms of Use</a> and you understand that your data will be processed in accordance with the <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Policy privacy</a> Mastercard.',
          c2pInfo: 'Your email or mobile number will be used to verify you. Message/data rates may apply.',
          buttonSubmit: 'Save and pay',
          buttonBack: 'Pay without saving your card'
        },
        profile: {
          header: 'Log in to Click to Pay',
          buttonChangeEmail: 'Change email address'
        },
        modal: {
          title: 'Click to Pay - information',
          header: 'Pay quickly and securely without re-entering your card details in the new Click to Pay standard',
          text1: 'When paying by card, look for the logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> and pay with your card payment quickly and conveniently.',
          text2: 'Click To Pay is a new card payment standard supported by Visa and Mastercard.',
          text3: 'You can use the card on your Click to Pay account to pay in stores in Poland and abroad, without re-entering your card details.',
          text4: 'The data is stored securely in Visa or Mastercard. All information is fully encrypted.',
          text5: 'During future payments, Click to Pay will recognize your e-mail address, so you can instantly pay with your saved cards.',
          text6: 'You additionally confirm all payments with your bank, which guarantees full security.',
          processError: 'An error occurred while processing your Click to Pay payment. Make a standard card payment.'
        }
      },
      email: {
        label: 'Καταχωρήστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου',
        flowLabel: 'E-mail',
        tip: 'Χρειαζόμαστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας ώστε να μπορούμε να σας στέλνουμε μηνύματα σχετικά με την κατάσταση των πληρωμών σας.'
      },
      terms: {
        header: 'Συναινέσεις και δηλώσεις',
        selectAll: 'Επιλογή όλων',
        fieldNotRequired: 'Το πεδίο δεν είναι υποχρεωτικό',
        partnerMarketingTextLess: 'Θα θέλατε να σας στέλνουμε ειδοποιήσεις μέσω email, SMS ή τηλεφώνου σχετικά με τις καλύτερες προσφορές, προωθητικές ενέργειες, διαγωνισμούς και άλλες ενδιαφέρουσες εκδηλώσεις που διοργανώνονται από εμάς ή τους εταίρους μας;',
        partnerMarketingTextMore: 'Υποσχόμαστε να μην χρησιμοποιούμε spam! Αν τα μηνύματά μας σας ενοχλούν, μπορείτε να ανακαλέσετε τη συγκατάθεσή σας ανά πάσα στιγμή.',
        tradeMarketingTextLess: 'Θα θέλατε να διαβιβάσουμε τη διεύθυνση του ηλεκτρονικού ταχυδρομείου ή τον αριθμό τηλεφώνου σας στους έμπιστους εταίρους μας, ώστε να σας παρουσιάζουν ενδιαφέρουσες πληροφορίες και προσφορές;',
        tradeMarketingTextMore: 'Σας διαβεβαιώνουμε ότι οι εταίροι μας είναι αναγνωρισμένες εταιρείες στις οποίες, όπως και σε εμάς, δεν αρέσει το spamming ενώ μπορείτε να ανακαλέσετε τη συγκατάθεσή σας ανά πάσα στιγμή.'
      },
      termsLabelDefault: 'Έχω διαβάσει και αποδέχομαι <a href="{regulationUrl}" class="form-text-link" target="_blank" title=" Κατεβάστε το αρχείο με τους όρους και προϋποθέσεις της υπηρεσίας " rel="noreferrer"> Όροι και Προϋποθέσεις </a> παροχής υπηρεσιών πληρωμών και <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title=" Κατεβάστε το αρχείο με την πολιτική απορρήτου Autopay S.A.">Πολιτική απορρήτου</a>. Θέλω η υπηρεσία να παρασχεθεί χωρίς καθυστέρηση και, σε περίπτωση ανάκλησης της σύμβασης, γνωρίζω ότι δεν θα αποζημιωθώ για τις υπηρεσίες που παρασχέθηκαν, κατόπιν αιτήματός μου, έως τη στιγμή που έλαβε χώρα η ανάκληση.',
      termsReccuring: 'Εάν επιθυμείτε να κάνετε μια πληρωμή, αποδεχτείτε <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Κατεβάστε το αρχείο με τους όρους και προϋποθέσεις της υπηρεσίας" rel="noreferrer"> Όροι και Προϋποθέσεις </a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Κατεβάστε το αρχείο της πολιτικής απορρήτου Autopay S.A.">την Πολιτική Απορρήτου</a> Autopay S.A., συναίνεση στην άμεση εκτέλεση της υπηρεσίας πληρωμών και στη χρέωση της κάρτας πληρωμών μου ',
      termsReccuringMore1: 'Συμφωνώ ότι η υπηρεσία θα παρασχεθεί χωρίς καθυστέρηση και, σε περίπτωση υπαναχώρησης, γνωρίζω ότι δεν θα αποζημιωθώ για τις υπηρεσίες που παρασχέθηκαν κατόπιν αιτήματός μου έως ότου υπαναχωρήσω από τη σύμβαση.',
      termsReccuringMore2: 'Συμφωνώ με την κυκλική χρέωση από την Autopay S.A. της κάρτας πληρωμών μου με σκοπό τη διεκπεραίωση συναλλαγών για λογαριασμό του {receiverName}. Οι όροι και προϋποθέσεις για την κυκλική χρέωση της κάρτας καθορίζονται στο <a href="{regulationUrl}" class="form-text-link" target="_blank" title=" Κατεβάστε το αρχείο με τους όρους και προϋποθέσεις της υπηρεσίας " rel="noreferrer"> Όροι και Προϋποθέσεις</a> παροχής υπηρεσιών πληρωμών. Δηλώνω ότι γνωρίζω τις προθεσμίες, τη συχνότητα και τους κανόνες για τον καθορισμό των ποσών που χρεώνονται στην κάρτα πληρωμών μου, καθώς και τον τρόπο ανάκλησης της παρούσας συγκατάθεσης, τα οποία έχουν συμφωνηθεί από εμένα με τον {receiverName}. Σε περίπτωση ερωτήσεων σχετικά με την κυκλική χρέωση της κάρτας πληρωμών, θα επικοινωνήσω με τον {receiverName}.',
      mwfClauseLess: 'Εάν επιθυμείτε να κάνετε μια πληρωμή, αποδεχτείτε <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Κατεβάστε το αρχείο με τους όρους και προϋποθέσεις της υπηρεσίας" rel="noreferrer"> Όροι και Προϋποθέσεις </a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title=" Κατεβάστε το αρχείο της πολιτικής απορρήτου Autopay S.A."> την πολιτική απορρήτου Autopay S.A.</a>, συναίνεση στην έναρξη μιας συναλλαγής στην τράπεζά σας μέσω της Autopay S.A. και στην παράδοση των στοιχείων της εν λόγω συναλλαγής στον δικαιούχο και αίτημα για την άμεση εκτέλεση της υπηρεσίας πληρωμών.',
      mwClauseMore: 'Θέλω η υπηρεσία να παρασχεθεί άμεσα και γνωρίζω ότι δεν θα μου επιστραφούν τα δαπανηθέντα χρήματα σε περίπτωση υπαναχώρησης από τη σύμβαση.',
      wfClauseARIA: 'Εάν επιθυμείτε να πραγματοποιήσετε μια πληρωμή, παρακαλούμε αποδεχτείτε τους Όρους και Προϋποθέσεις και την πολιτική απορρήτου της Autopay S.A., τη συγκατάθεση για έναρξη μιας συναλλαγής με την τράπεζά σας μέσω της Autopay S.A. και την παροχή λεπτομερειών αυτής της συναλλαγής στον δικαιούχο, καθώς και την αίτηση για άμεση υλοποίηση της υπηρεσίας πληρωμής. Επιθυμώ η υπηρεσία να παρασχεθεί άμεσα και γνωρίζω ότι δεν θα λάβω επιστροφή χρημάτων εάν υπαναχωρήσω από τη σύμβαση. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1"> Κατεβάστε το αρχείο με τους όρους και προϋποθέσεις της υπηρεσίας </a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1"> Κατεβάστε το αρχείο με την πολιτική απορρήτου Autopay S.A.</a>',
      paymentInfo: 'Η εντολή πληρωμής υποβάλλεται μέσω της Autopay S.A. με έδρα το Sopot και θα εκτελεστεί σύμφωνα με τους όρους και τις προϋποθέσεις που θέτει η τράπεζά σας». Po&nbsp; την επιλογή της τράπεζας θα εγκρίνετε την πληρωμή.',
      changePaywayLink1: 'Μπορείτε ακόμα ',
      changePaywayLink2: 'να αλλάξετε τη μέθοδο πληρωμής ',
      linkMore: 'ΠΕΡΙΣΣΟΤΕΡΑ',
      linkShowMoreTitle: 'Εμφάνιση επιπλέον πληροφοριών ',
      linkLess: 'ΛΙΓΟΤΕΡΑ ',
      linkShowLessTitle: 'Απόκρυψη επιπλέον πληροφοριών ',
      submitButton: {
        visaMobile: 'Πληρώστε μέσω ',
        fastTransfer: 'Δημιουργία στοιχείων για μεταφορά ',
        mwf: 'Αποδέχομαι και πληρώνω ',
        pis: 'Αποδέχομαι και ξεκινώ την πληρωμή ',
        auto: 'Ανάθεση επαναλαμβανόμενης πληρωμής',
        autoPayment: 'Ανάθεση πληρωμής ',
        other: 'Πληρώνω ',
        orange: 'Επιβεβαίωση ',
        c2pNext: 'Επόμενο ',
        c2pAccept: 'Αποδέχομαι ',
        c2pSaveAndPay: 'Αποθηκεύστε και πληρώστε '
      }
    },
    frData: {
      pageTitle: 'Αναλυτικά στοιχεία μεταφοράς ',
      header: 'Εκτελέστε μεταφορά χρησιμοποιώντας τα δεδομένα ',
      nrbLabel: 'Λογαριασμός παραλήπτη ',
      nameLabel: 'Όνομα παραλήπτη ',
      addressLabel: 'Διεύθυνση παραλήπτη ',
      titleLabel: 'Τίτλος μεταφοράς',
      amountLabel: 'Ποσό μεταφοράς',
      timeLabel: 'Ολοκληρώστε τη συναλλαγή μέχρι ',
      linkCopy: 'Αντιγραφή',
      linkGetPdf: 'Λήψη PDF με τα δεδομένα',
      linkGetPdfTitle: 'Ανοίγει σε νέο παράθυρο',
      linkGoToBank: 'Συνδεθείτε στην τράπεζα ',
      info: 'Συνδεθείτε στον τραπεζικό σας λογαριασμό. Αντιγράψτε τα δεδομένα για τη μεταφορά. Είναι σημαντικό τα δεδομένα να είναι ίδια, να μην αλλάζετε το ποσό, το νόμισμα, τον τίτλο κ.λπ. '
    },
    processing: {
      pageTitle: 'Σελίδα αναμονής για την επεξεργασία της συναλλαγής',
      loaderAnimation: 'Κινούμενη εικόνα εμφανίζεται κατά τη διάρκεια της αναμονής',
      header: 'Προετοιμάζουμε τη σελίδα πληρωμής σας.',
      description: 'Περιμένετε λίγο ακόμα... ',
      info: {
        header: 'Θυμηθείτε !',
        description: 'Μην κλείσετε τη σελίδα μετά την ολοκλήρωση της συναλλαγής - αν το κάνετε, τα χρήματά σας δεν θα μεταφερθούν στον παραλήπτη '
      },
      loaderText: 'Ολοκληρώνεται η μεταφορά δεδομένων.<br> Αυτό θα μας πάρει το πολύ 30 δευτερόλεπτα '
    }
  },
  thankYou: {
    pageTitle: 'Πληροφορίες αποτελέσματος συναλλαγής ',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Επιστροφή στη σελίδα του συνεργάτη '
    },
    negative: {
      header: 'Η πληρωμή δεν ολοκληρώθηκε λόγω έλλειψης έγκρισης της συναλλαγή.',
      description: 'Αν θέλετε ακόμα να επωφεληθείτε από την προσφορά του συγκεκριμένου πωλητή ή παρόχου υπηρεσιών - επιστρέψτε στην ιστοσελίδα του, επιλέξτε ένα προϊόν ή μια υπηρεσία και κάντε την πληρωμή χρησιμοποιώντας τη μέθοδο που επιλέξατε.'
    },
    pending: {
      header: 'Ευχαριστούμε για την εντολή πληρωμής μέσω πύλης πληρωμών.',
      description: 'Ελέγχεται ο κατάσταση της συναλλαγής. Όταν ολοκληρωθεί η διαδικασία, θα λάβετε ειδοποίηση για την κατάσταση της πληρωμής στο ηλεκτρονικό ταχυδρομείο σας.'
    },
    positive: {
      header: 'Σας ευχαριστούμε, η πληρωμή σας ολοκληρώθηκε.',
      description: 'Θα στείλουμε την επιβεβαίωση στην ηλεκτρονική σας διεύθυνσ.'
    }
  },
  regulationsApproval: {
    header: 'Εγκρίνετε τη συναλλαγή '
  },
  wait: {
    pageTitle: 'Σελίδα αναμονής '
  },
  sessionTimeout: {
    pageTitle: 'Σελίδα μετά το πέρας της συνεδρίας ',
    header: 'Ο χρόνος για την ολοκλήρωση της πληρωμής έληξε.',
    description: 'Μπορείτε να επιστρέψετε οποτεδήποτε στη σελίδα του εταίρου, να κάνετε ξανά παραγγελία ή να χρησιμοποιήσετε την υπηρεσία και να κάνετε πληρωμή χρησιμοποιώντας τη μέθοδο που επιλέξατε.'
  },
  paywayError: {
    pageTitle: 'Επιλεγμένος τρόπος πληρωμής μη διαθέσιμος ',
    header: 'Δυστυχώς ο επιλεγμένος τρόπος πληρωμής δεν είναι αυτή τη στιγμή διαθέσιμος.',
    description: 'Επιστρέψτε στη σελίδα του συνεργάτη και δοκιμάστε ξανά αργότερα.'
  },
  loader: {
    defaultAnimation: 'Κινούμενη εικόνα που εμφανίζεται κατά τη φόρτωση της σελίδας',
    slider: 'Διαδοχή κειμένων με κινούμενη εικόνα',
    text1: 'Στην Autopay προσπαθούμε να είμαστε άνθρακας-αρνητικοί. Κάνετε και εσείς το ίδιο χρησιμοποιώντας τις υπηρεσίες μας.',
    text2: 'Στην Autopay πηγαίνουμε στη δουλειά με ποδήλατα μειώνοντας τις εκπομπές αερίων του θερμοκηπίου στην ατμόσφαιρα. Κάνετε και εσείς το ίδιο χρησιμοποιώντας τις υπηρεσίες μας.',
    text3: 'Στην Autopay τροφοδοτούμαστε από πράσινη ενέργεια.<br>Και εσείς τροφοδοτείστε όταν χρησιμοποιείτε τις υπηρεσίες μας.',
    text4: 'Στην Autopay περιορίζουμε τη χρήση χαρτιού. Κάνετε και εσείς το ίδιο χρησιμοποιώντας τις υπηρεσίες μας..',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Λεπτομέρειες ',
    headerAriaLabel: 'Λεπτομέρειες συναλλαγής ',
    amountLabel: 'Ποσό οφειλής ',
    receiverLabel: 'Παραλήπτης ',
    orderLabel: 'Αριθμός παραγγελίας ',
    amountOryginalCurrencyLabel: 'Ποσό σε  {συννάλαγμα}',
    currencyRateLabel: 'Ισοτιμία νομίσματος ',
    feeLabel: 'Κόστος εκτέλεσης συναλλαγής ',
    customerNumberLabel: 'Αριθμός πελάτη ',
    invoiceNumberLabel: 'Αριθμός τιμολογίου ',
    accountNumberLabel: 'Αριθμός λογαριασμού ',
    paywayLabel: 'Μέθοδος πληρωμής ',
    paywayName: 'Διαδικτυακή μεταφορά',
    linkBack: 'Επιστροφή στο κατάστημα ',
    linkBackTitle: 'Ακύρωση συναλλαγής και επιστροφή στο κατάστημα',
    linkMore: 'περισσότερα',
    linkLess: 'λιγότερα',
    toggleBtnUnfoldAriaLabel: 'Ανάπτυξη λεπτομερειών παραγγελίας',
    toggleBtnFoldAriaLabel: 'Σύμπτυξη λεπτομερειών παραγγελίας'
  },
  error: {
    pageTitle: 'Σελίδα με πληροφορίες σφάλματος',
    linkBackUrlSet: 'Επιστροφή στη σελίδα του συνεργάτη',
    linkBackUrlNotSet: 'Μετάβαση στη σελίδα βοήθειας',
    sessionTimeout: {
      title: 'Η περίοδος για ολοκλήρωση της πληρωμής έληξε',
      message: 'Μπορείτε ανά πάσα στιγμή να επιστρέψετε στη σελίδα του συνεργάτη, να κάνετε ξανά την παραγγελία σας ή να χρησιμοποιήσετε την υπηρεσία και να πραγματοποιήσετε την πληρωμή σας με την επιλεγμένη από εσάς μέθοδο.'
    },
    notFoundError: {
      pageTitle: 'Σελίδα με πληροφορίες σφάλματος 404 - η σελίδα δεν βρέθηκε',
      title: 'Φαίνεται ότι πήγατε πολύ μακριά και δεν ήταν δυνατή η εύρεση της σελίδας που προσπαθείτε να επισκεφθείτε.',
      message: 'Πιθανές αιτίες: η σελίδα δεν υπάρχει ή έχει διαγραφεί, διενεργούνται εργασίες συντήρησης, ο διεύθυνση της σελίδας είναι εσφαλμένη, παρουσιάστηκε τεχνικό σφάλμα.'
    },
    generalError: {
      title: 'Λυπούμαστε',
      message: 'Προς το παρόν δεν είμαστε σε θέση να ολοκληρώσουμε τη μεταφορά σας.'
    },
    errFieldNotFound: {
      title: 'Λυπούμαστε',
      message: ''
    },
    errBadClientSource: {
      title: 'Λυπούμαστε',
      message: ''
    },
    nrParametersError: {
      title: 'Λυπούμαστε',
      message: 'Προς το παρόν δεν είμαστε σε θέση να ολοκληρώσουμε τη μεταφορά σας.'
    },
    transactionOutdated: {
      title: 'Ο χρόνος για την ολοκλήρωση της πληρωμής έληξε.',
      message: 'Κάντε την εκ νέου.'
    },
    linkValidityTimeOutdated: {
      title: 'Ο χρόνος για την ολοκλήρωση της πληρωμής έληξε.',
      message: 'Κάντε την εκ νέου.'
    },
    transactionValidityTimeOutdated: {
      title: 'Ο χρόνος για την ολοκλήρωση της πληρωμής έληξε.',
      message: 'Κάντε την εκ νέου.'
    },
    multiplyTransaction: {
      title: 'Λυπούμαστε',
      message: 'Η συναλλαγή υπάρχει ήδη και αναμένει πληρωμή.'
    },
    transactionCanceled: {
      title: 'Λυπούμαστε',
      message1: 'Η συναλλαγή ακυρώθηκε.',
      message2: 'Κάντε την εκ νέου.'
    },
    multiplyPaidTransaction: {
      title: 'Λυπούμαστε',
      message: 'Η συναλλαγή έχει ήδη πληρωθεί.'
    },
    transactionReject: {
      title: 'Λυπούμαστε',
      message1: 'Ούπς, κάτι πήγε στραβά.',
      message2: 'Διορθώνουμε ήδη την πύλη...'
    },
    bankDisabled: {
      title: 'Λυπούμαστε',
      message: 'Η επιλεγμένη τράπεζα δεν είναι αυτή τη στιγμή διαθέσιμη.'
    },
    bankTemporaryMaintenance: {
      title: 'Λυπούμαστε',
      message: 'Προς το παρόν δεν μπορούμε να ολοκληρώσουμε τη συναλλαγή σας.'
    },
    insufficientStartAmount: {
      title: 'Λυπούμαστε',
      message: 'Υπερβαίνεται το όριο του ποσού της συναλλαγής.'
    },
    startAmountOutOfRange: {
      title: 'Λυπούμαστε',
      message: 'Υπερβαίνεται το όριο του ποσού της συναλλαγής.'
    },
    nonAccountedLimitExceeded: {
      title: 'Λυπούμαστε',
      message: 'Η πληρωμή δεν μπορεί να πραγματοποιηθεί επειδή ο πωλητής έχει ξεπεράσει τον μη λογιστικό μηνιαίο του όριο πωλήσεων.'
    }
  },
  confirmation: {
    pageTitle: 'Πληροφορίες για την πορεία της πληρωμής',
    linkBack: 'Επιστροφή στη σελίδα',
    linkBackTimeout: 'Επιστροφή στη σελίδα του συνεργάτη σε ',
    incorrectAmount: {
      header: 'Λάθος ποσό',
      description: 'Δεν μεταφέραμε την πληρωμή σας στον παραλήπτη επειδή το ποσό δεν ταίριαζε.',
      description2: 'Κάντε κλικ στο κουμπί για να επιστρέψετε στη σελίδα και να την επαναλάβετε. Θυμηθείτε να μην αλλάξετε καμία πληροφορία.'
    },
    multiplePaid: {
      header: 'Διπλή πληρωμή',
      description: 'Ο παραλήπτης έλαβε την πληρωμή σας δύο φορές. Μην ανησυχείτε - θα λάβετε επιστροφή.',
      description2: 'Επικοινωνήστε με τον παραλήπτη και δώστε του την επιβεβαίωση πληρωμής.'
    },
    negative: {
      header: 'Απουσία πληρωμής',
      description: 'Κατά τη διάρκεια της επεξεργασίας της πληρωμής σας παρουσιάστηκε σφάλμα. Ωστόσο, δεν έχετε χάσει τίποτα.',
      description2: 'Κάντε κλικ στο κουμπί για να επιστρέψετε στη σελίδα και να την επαναλάβετε.'
    },
    notFound: {
      header: 'Απουσία πληρωμής',
      description: 'Κατά τη διάρκεια της επεξεργασίας της πληρωμής σας παρουσιάστηκε σφάλμα. Ωστόσο, δεν έχετε χάσει τίποτα.',
      description2: 'Κάντε κλικ στο κουμπί για να επιστρέψετε στη σελίδα και να την επαναλάβετε.'
    },
    outdated: {
      header: 'Λήξη χρόνου',
      description: 'Δεν μεταφέραμε την πληρωμή σας στον παραλήπτη.',
      description2: 'Κάντε κλικ στο κουμπί για να επιστρέψετε στη σελίδα και να την επαναλάβετε.'
    },
    outdatedPaidNotRefunded: {
      header: 'Λήξη χρόνου',
      description: 'Δεν μεταφέραμε την πληρωμή σας στον παραλήπτη. Αν τα χρήματα έχουν ήδη χρεωθεί από τον λογαριασμό σας - μην ανησυχείτε, θα λάβετε επιστροφή μέσα σε λίγες ημέρες.',
      description2: 'Κάντε κλικ στο κουμπί για να επιστρέψετε στη σελίδα και να την επαναλάβετε.'
    },
    outdatedPaidRefunded: {
      header: 'Λήξη χρόνου',
      description: 'Δεν μεταφέραμε την πληρωμή σας στον παραλήπτη. Αν τα χρήματα έχουν ήδη χρεωθεί από τον λογαριασμό σας - μην ανησυχείτε, τα έχουμε ήδη επιστρέψει.',
      description2: 'Κάντε κλικ στο κουμπί για να επιστρέψετε στη σελίδα και να την επαναλάβετε.'
    },
    pending: {
      header: 'Εκκρεμής ',
      description: 'Εκτελούμε την πληρωμή σας. Θα σας ενημερώσουμε όταν φτάσει στον παραλήπτη.'
    },
    positive: {
      header: 'Πληρώθηκε ',
      visaVideo: 'Διαφημιστικό βίντεο που προβάλλεται μετά από επιτυχή συναλλαγή',
      description: 'Μεταφέραμε τα χρήματά σας στον παραλήπτη.'
    }
  },
  messages: {
    accessForbidden: 'Brak dostępu',
    internalServerError: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
    validators: {
      required: 'Υποχρεωτικό πεδίο',
      pattern: 'Καταχωρίστε μια έγκυρη τιμή',
      emailRequired: 'Εισάγετε μια έγκυρη διεύθυνση email',
      emailNotValid: 'Εισάγετε μια έγκυρη διεύθυνση email',
      emailNotRecognized: 'Η διεύθυνση email που καταχωρίσατε δεν είναι εγγεγραμμένη',
      emailIdentityLookup: 'Αδυναμία επαλήθευσης της διεύθυνσης email',
      emailLocked: 'Η διεύθυνση email που καταχωρίσατε είναι προσωρινά κλειδωμένη στο σύστημα Mastercard',
      paywayRequired: 'Για να πραγματοποιήσετε πληρωμή, επιλέξτε την τράπεζα',
      creditCardRequired: 'Συμπληρώστε όλα τα πεδία',
      phoneRequired: 'Εισάγετε μια έγκυρη αριθμό τηλεφώνου',
      regulationRequired: 'Αποδοχή των ρυθμίσεων απαιτείται',
      requiredConsent: 'Απαιτείται συγκατάθεση',
      codeNotValid: 'Ο κωδικός που καταχωρίσατε δεν είναι έγκυρος',
      codeAccountLocked: 'Το λογαριασμός έχει κλειδωθεί προσωρινά στο σύστημα Mastercard'
    },
    validatorsAria: {
      required: 'Προσοχή. Το πεδίο της φόρμας δεν έχει συμπληρωθεί. Υποχρεωτικό πεδίο ',
      pattern: 'Προσοχή. Το πεδίο περιέχει μια μη έγκυρη τιμή.',
      emailRequired: 'Προσοχή. Το πεδίο email δεν έχει συμπληρωθεί. Εισάγετε μια έγκυρη διεύθυνση email.',
      emailNotValid: 'Προσοχή. Η διεύθυνση email που καταχωρίσατε περιέχει σφάλμα. Εισάγετε μια έγκυρη διεύθυνση email ',
      emailNotRecognized: 'Προσοχή. Η διεύθυνση email που καταχωρίσατε δεν είναι εγγεγραμμένη.',
      emailIdentityLookup: 'Προσοχή. Αδυναμία επαλήθευσης της διεύθυνσης email',
      emailLocked: 'Προσοχή. Η διεύθυνση email που καταχωρίσατε είναι προσωρινά κλειδωμένη στο σύστημα Mastercard.',
      paywayRequired: 'Προσοχή. Δεν έχει επιλεγεί πληρωμή. Επιλέξτε μια μέθοδο πληρωμής.',
      creditCardRequired: 'Προσοχή. Συμπληρώστε όλα τα πεδία με τα δεδομένα της πιστωτικής κάρτας. ',
      phoneRequired: 'Προσοχή. Το πεδίο αριθμού τηλεφώνου περιέχει σφάλμα. Εισάγετε έναν έγκυρο αριθμό τηλεφώνου ',
      codeNotValid: 'Προσοχή. Ο κωδικός που καταχωρίσατε δεν είναι έγκυρος. ',
      codeAccountLocked: 'Προσοχή. Ο λογαριασμός έχει κλειδωθεί προσωρινά στο σύστημα Mastercard.'
    },
    success: {
      copy: 'Αντιγράφηκε!',
      codeSent: 'Ο κωδικός εστάλη!'
    },
    errors: {
      getPdf: 'Απέτυχε η λήψη του αρχείου PDF !',
      copy: 'Απέτυχε η αντιγραφή !',
      thankYouUpdate: 'Απέτυχε η αποθήκευση της διεύθυνσης email!',
      groupInactive: 'Προς το παρόν, η εκτέλεση μιας μεταφοράς μέσω της ομάδας {groupName} δεν είναι δυνατή. Επιλέξτε μια άλλη μέθοδο πληρωμής.',
      paywayInactive: 'Προς το παρόν, η εκτέλεση μιας μεταφοράς από την επιλεγμένη τράπεζα δεν είναι δυνατή. Επιλέξτε μια άλλη τράπεζα ή μέθοδο πληρωμής.',
      googlePayInactive: 'Δυστυχώς, η πληρωμή Google Pay δεν είναι διαθέσιμη στη συσκευή σας.',
      applePayInactive: 'Η πληρωμή Apple Pay είναι διαθέσιμη για χρήστες που χρησιμοποιούν τον περιηγητή Safari και συσκευές με λειτουργικό σύστημα iOS.',
      codeNotSent: 'Απέτυχε η αποστολή του κωδικού ξανά! ',
      codeCounterExceeded: 'Υπερβέθηκε το επιτρεπόμενο όριο προσπαθειών. Καταχωρήστε τα δεδομένα της κάρτας χειροκίνητα ή χρησιμοποιήστε μια διαφορετική μέθοδο πληρωμής.'
    }
  },
  modal: {
    closeButton: 'Κλείσιμο ',
    confirmButton: 'Κατάλαβα '
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Αυτόματη πληρωμή ',
        description: 'Καταχωρίστε τα στοιχεία της κάρτας που θα χρησιμοποιήσετε για τις πληρωμές. Οι αυτόματες πληρωμές θα πραγματοποιούνται από την κάρτα σας στο μέλλον.',
        labelInitWithRefund: 'Επιβεβαιωτική πληρωμή ',
        labelInitWithRefundOrange: 'Προσθήκη πιστωτικής κάρτας ',
        labelInitWithPayment: 'Πληρωμή με κάρτα',
        descriptionInitWithRefund: 'Καταχωρίστε τα στοιχεία της κάρτας που θέλετε να επιβεβαιώσετε για μελλοντικές πληρωμές. Στο επόμενο βήμα, θα χρεωθείτε με ένα ευρώ από την καταχωρημένη κάρτα για τον έλεγχο των δεδομένων. Μετά την επιβεβαίωση, το ποσό θα επιστραφεί.',
        descriptionInitWithPayment: 'Καταχωρίστε τα στοιχεία της κάρτας με την οποία θα πραγματοποιήσετε τη συναλλαγή και ταυτόχρονα θα την επιβεβαιώσετε για μελλοντικές πληρωμές.'
      },
      blik: {
        label: 'BLIK',
        description: 'Πληρώστε άμεσα εισάγοντας τον κωδικό BLIK, χωρίς σύνδεση και εγγραφή'
      },
      card: {
        label: 'Πληρωμή με κάρτα ',
        description: 'Πληρώστε με την πιστωτική σας κάρτα '
      },
      pbl: {
        label: 'Διαδικτυακή μεταφορά ',
        description: 'Επιλέξτε την τράπεζα από την οποία θέλετε να κάνετε την πληρωμή ',
        descriptionVerify: 'Έλεγχος ταυτότητας online ',
        descriptionServiceVerify: 'Επιλέξτε την τράπεζα με την οποία θα πραγματοποιήσετε την επαλήθευση της διαδικτυακής υπηρεσίας'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Πληρώστε με την εφαρμογή Visa Mobile '
      },
      wallet: {
        label: 'Εικονικό πορτοφόλι ',
        description: 'Πληρώστε χωρίς να χρειάζεται να συνδεθείτε στην ηλεκτρονική τραπεζική'
      },
      installments: {
        label: 'Δόσεις ',
        description: 'Πληρώστε τις αγορές σας με εύκολες δόσεις'
      },
      alior: {
        descriptionInfo1: 'Πληρώστε τις αγορές σας με εύκολες δόσεις ',
        descriptionInfo2: '(ελέγξτε τις λεπτομέρειες) ',
        aliorModal: {
          text1: 'Ανάλογα με τις ρυθμίσεις του σετ που χρησιμοποιείτε, έχετε τη δυνατότητα να αγοράζετε προϊόντα με τις ακόλουθες επιλογές δόσεων: ',
          text2: '<ul><li>10 δόσεις 0%</li><li>20 δόσεις 0%</li><li>από 3 έως 48 δόσεις με μηνιαίο κόστος 1% (σύμφωνα με το παράδειγμα που δίνεται για κάθε συγκεκριμένη συναλλαγή)</li></ul> '
        }
      },
      otp: {
        label: 'Αγοράστε τώρα, πληρώστε αργότερα',
        description: 'Αγοράστε τώρα πληρώστε αργότερα ',
        descriptionInfo1Mwf: 'Πληρώστε αργότερα το λογαριασμό σας - έως 45 ημέρες κάθε φορά ή σε πολλές ισόποσες δόσεις ',
        descriptionInfo1Ecommerce: 'Πληρώστε αργότερα - έως 45 ημέρες κάθε φορά ή σε πολλές ισόποσες δόσεις',
        descriptionInfo2Mwf: 'Πληροφορίες για το μεσίτη πίστωσης και τα κόστη ',
        descriptionInfo2Ecommerce: 'Πληροφορίες κόστους',
        labelPayka: 'Payka',
        descriptionPayka: ' <a href="" id="paykaShowInModal"></a>',
        descriptionBlikPayLater: 'Υπηρεσία διαθέσιμη για πελάτες της Τράπεζας Millennium και της VeloBank ',
        paykaModal: {
          header1: 'Πληροφορίες για τον μεσίτη πίστωσης ',
          text1: 'Η Autopay Α.Ε., με έδρα στο Sopot, λειτουργεί ως μεσίτης πίστωσης της Paytree Α.Ε., με έδρα στο Gdańsk (Δανειοδότης), έχοντας την αρμοδιότητα να παρουσιάζει στους πελάτες προσφορές δανείου και να τους ανακατευθύνει στον ιστότοπο του Δανειοδότη, συμπεριλαμβανομένης της αίτησης δανείου. Λεπτομέρειες προσφοράς στο: <a href="https://payka.pl/rachunki-domowe" target="_blank">Οικιακοί Λογαριασμοί - Payka</a>. ',
          header2: 'Στοιχεία Κόστους ',
          text2: 'Πληρώστε αργότερα - έως 45 ημέρες κάθε φορά ή σε πολλές ισόποσες δόσεις. Στοιχεία κόστους για αντιπροσωπευτικό παράδειγμα: Εξόφληση εντός 30 ημερών ΑΠΡΙ 0%. Αποπληρωμή δόσεων ΑΠΡ 86,83%. Λεπτομέρειες προσφοράς για: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Στοιχεία κόστους',
          text2: 'Πληρώστε αργότερα - έως 45 ημέρες κάθε φορά ή σε πολλές ισόποσες δόσεις. Στοιχεία κόστους για αντιπροσωπευτικό παράδειγμα: Εξόφληση εντός 30 ημερών ΑΠΡΙ 0%. Αποπληρωμή δόσεων ΑΠΡ 86,83%. Λεπτομέρειες προσφοράς για: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Αγοράστε τώρα, πληρώστε &nbsp;εντός  &nbsp;30&nbsp;ημερών ',
          header2: 'Κωδικός BLIK',
          text2: 'Εισάγετε τον κωδικό BLIK, και σας προσφέρουμε έως και 4.000 PLN όριο αγορών',
          header3: 'Αγοράζετε προϊόντα, τα οποία πληρώνετε αργότερα ',
          text3: 'Έχετε χρόνο να ελέγξετε αν σας αρέσουν ',
          header4: 'Πληρώνετε εντός 30 ημερών χωρίς κόστος ',
          text4: 'Μπορείτε επίσης να επιστρέψετε τις αγορές σας μέσα στην προβλεπόμενη προθεσμία από το κατάστημα '
        }
      },
      companyPayments: {
        label: 'Εταιρικές Πληρωμές ',
        description: 'Μετακίνηση της ημερομηνίας πληρωμής για εταιρικές αγορές κατά 30 ημέρες'
      },
      transfer: {
        label: 'Στοιχεία Μεταφοράς ',
        description: 'Δώστε εντολή για μεταφορά χρημάτων χρησιμοποιώντας τα παρακάτω στοιχεία'
      },
      other: {
        label: 'Άλλα',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Λυπούμαστε. Προς το παρόν δεν είμαστε σε θέση να εκτελέσουμε τη μεταφορά σας. Η τράπεζα που επιλέξατε δεν είναι διαθέσιμη αυτήν τη στιγμή ',
        blockMultipleTransactions: 'Λυπούμαστε. Η συναλλαγή υπάρχει ήδη και περιμένει για πληρωμή.',
        blockPaidTransactions: 'Λυπούμαστε. Η συναλλαγή έχει ήδη πληρωθεί. Δεν είναι δυνατή η εκτέλεση πληρωμής ξανά.',
        canceledTransactions: 'Η συναλλαγή που προσπαθείτε να πληρώσετε έχει ακυρωθεί και δεν είναι διαθέσιμη. Παρακαλούμε ξεκινήστε μια νέα συναλλαγή.',
        declined: 'Άρνηση έγκρισης.',
        generalError: 'Προέκυψε προσωρινό πρόβλημα με τη σύνδεση. Αυτήν τη στιγμή δεν μπορούμε να εκτελέσουμε τη μεταφορά σας. Παρακαλούμε δοκιμάστε αργότερα.',
        insufficientStartAmount: 'Λυπούμαστε. Ο περιορισμός του ποσού μεταφοράς έχει υπερβείτε.',
        internalServerError: 'Ωχ.. κάτι πήγε στραβά :(. Εργαζόμαστε για την επίλυση του προβλήματος. Παρακαλούμε δοκιμάστε ξανά αργότερα.',
        nonAccountedLimitExceeded: 'Η πληρωμή δεν μπορεί να πραγματοποιηθεί, καθώς ο πωλητής έχει φτάσει το μηνιαίο όριο πωλήσεων.',
        outdatedError: 'Ο χρόνος για την ολοκλήρωση της πληρωμής έχει λήξει..',
        paid: 'Η συναλλαγή ολοκληρώθηκε επιτυχώς.',
        paywayLoaded: 'Η σελίδα ταχείας μεταφοράς φορτώθηκε ',
        paywaylistLoaded: 'Η σελίδα επιλογής μεθόδων πληρωμής φορτώθηκ '
      }
    }
  },
  maintenance: {
    text1: 'Γίνονται καθαρισμοί της πύλης πληρωμών της Autopay για τη νέα χρονιά, επομένως η εντολή πληρωμής είναι αδύνατη αυτήν τη στιγμή. Ζητούμε συγνώμη για την ταλαιπωρία..',
    text2: 'Η πύλη θα λειτουργήσει ξανά στις 16 Ιανουαρίου στις 10:00.',
    text3: 'Σας ευχαριστούμε για την κατανόηση.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Εισαγάγετε το όνομα και το επώνυμό σας',
      flowLabel: 'Όνομα και επώνυμο',
      placeholder: 'Εισαγάγετε το όνομα και το επώνυμό σας'
    },
    nip: {
      label: 'Εισαγάγετε τον αριθμό φορολογικού μητρώου σας',
      flowLabel: 'ΑΦΜ',
      placeholder: 'Εισαγάγετε τον αριθμό φορολογικού μητρώου σας'
    }
  }
}
